<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>User Edit</v-card-title>

          <v-card-text>
            <v-form ref="form" lazy-validation class="multi-col-validation">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="item.name" :rules="[rules.required]" label="First Name" outlined dense
                    placeholder="First Name" hide-details></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.lname" :rules="[rules.required]" label="Last Name" outlined dense
                    placeholder="Last Name" hide-details></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.email" disabled filled label="Email" outlined dense placeholder="Email"
                    hide-details></v-text-field>
                </v-col>


                <div style="display:flex; justify-content:center; align-items:center;">
                  <h3 style="margin-left:15px;">Category Name</h3>
                  <h3 style="margin-left:30px; font-weight:100 !important;">{{ item.cname }}</h3>
                </div>
                <!-- <v-col cols="12" md="6">
                  <v-text-field v-model="item.cname" disabled filled label="Category Name" outlined dense
                    placeholder="Category Name" hide-details></v-text-field>
                </v-col> -->

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.mobile" disabled filled label="Mobile" outlined dense placeholder="Mobile"
                    hide-details></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.address1" label="Address 1" outlined dense placeholder="Address 1"
                    hide-details></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.address2" label="Address 2" outlined dense placeholder="Address 2"
                    hide-details></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete v-model="item.state" :items="init.states" item-text="name" item-value="id"
                    label="State" chips clearable small-chips @change="getCities"></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete v-model="item.city" :items="cities" :disabled="cities.length == 0" item-text="name"
                    item-value="id" label="City" chips clearable small-chips></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.designation" label="Designation" outlined dense placeholder="Designation"
                    hide-details></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-dialog ref="dialogDOB" v-model="isDOB" :return-value.sync="item.dob" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computedDateFormattedMomentjs" :rules="[rules.required]"
                        label="Date Of Birth" readonly outlined dense hide-details v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="item.dob" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="isDOB = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.dialogDOB.save(item.dob)"> OK </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select v-model="item.color" :items="langs" label="Language" dense outlined></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.company_name" label="Company" outlined dense placeholder="Company"
                    hide-details></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input v-model="item.new_profile_image" label="Profile Image" outlined accept="image/*" dense
                    @change="setImg"></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img v-if="item.profile_img" :src="item.profile_img" class="preview-img" />
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input v-model="item.new_company_logo" label="Company Logo" outlined accept="image/*" dense
                    @change="setcImg"></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img v-if="item.company_logo" :src="item.company_logo" class="preview-img" />
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select v-model="item.type" :items="types" :rules="[rules.required]" label="Type" dense outlined>
                  </v-select>
                </v-col>

                <v-col v-if="item.type == 'Paid'" cols="12" md="6"> </v-col>

                <v-col v-if="item.type == 'Paid'" cols="12" md="6">
                  <v-dialog ref="dialogSD" v-model="isSD" :return-value.sync="item.plan_start_date" persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computedStartDate" label="Plan Start Date" readonly outlined dense
                        hide-details v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="item.plan_start_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="isSD = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.dialogSD.save(item.plan_start_date)"> OK </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col v-if="item.type == 'Paid'" cols="12" md="6">
                  <v-dialog ref="dialogED" v-model="isED" :return-value.sync="item.plan_end_date" persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computedEndDate" :rules="[rules.required]" label="Plan End Date" readonly
                        disabled outlined dense hide-details v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="item.plan_end_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="isED = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.dialogED.save(item.plan_end_date)"> OK </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <p color="warning"></p>
                <v-col v-if="item.type !== 'Paid'" cols="12" md="6"> </v-col>
                <!-- <template v-if="item.type == 'Paid'"> -->
                <v-col cols="12" md="6">
                  <v-file-input v-model="item.new_temp_img_1" label="Template Image 1" outlined accept="image/*" dense
                    @change="setTempImg1"></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img v-if="item.template_image1" :src="item.template_image1" class="preview-img" />
                  <v-btn v-if="item.template_image1" :loading="deleteTemp && deleteTempName == 'template_image1'"
                    absolute right="0" color="#ff4c51" small @click="removeTemp('template_image1')">
                    <v-icon>
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="item.new_temp_img_2" label="Template Image 2" outlined accept="image/*" dense
                    @change="setTempImg2"></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img v-if="item.template_image2" :src="item.template_image2" class="preview-img" />
                  <v-btn v-if="item.template_image2" :loading="deleteTemp && deleteTempName == 'template_image2'"
                    absolute right="0" color="#ff4c51" small @click="removeTemp('template_image2')">
                    <v-icon>
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="item.new_temp_img_3" label="Template Image 3" outlined accept="image/*" dense
                    @change="setTempImg3"></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img v-if="item.template_image3" :src="item.template_image3" class="preview-img" />
                  <v-btn v-if="item.template_image3" :loading="deleteTemp && deleteTempName == 'template_image3'"
                    absolute right="0" color="#ff4c51" small @click="removeTemp('template_image3')">
                    <v-icon>
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="item.new_temp_gif_1" label="Template GIF 1" outlined accept="image/*" dense
                    @change="setTempGif1"></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img v-if="item.template_gif1" :src="item.template_gif1" class="preview-img" />
                  <v-btn v-if="item.template_gif1" :loading="deleteTemp && deleteTempName == 'template_gif1'" absolute
                    right="0" color="#ff4c51" small @click="removeTemp('template_gif1')">
                    <v-icon>
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="item.new_temp_gif_2" label="Template GIF 2" outlined accept="image/*" dense
                    @change="setTempGif2"></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img v-if="item.template_gif2" :src="item.template_gif2" class="preview-img" />
                  <v-btn v-if="item.template_gif2" :loading="deleteTemp && deleteTempName == 'template_gif2'" absolute
                    right="0" color="#ff4c51" small @click="removeTemp('template_gif2')">
                    <v-icon>
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="item.new_temp_gif_3" label="Template GIF 3" outlined accept="image/*" dense
                    @change="setTempGif3"></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img v-if="item.template_gif3" :src="item.template_gif3" class="preview-img" />
                  <v-btn v-if="item.template_gif3" :loading="deleteTemp && deleteTempName == 'template_gif3'" absolute
                    right="0" color="#ff4c51" small @click="removeTemp('template_gif3')">
                    <v-icon>
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <!-- </template> -->

                <v-col cols="12">
                  <v-btn :loading="process" color="primary" @click="submit"> Submit </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'
import moment from 'moment'
import { format, parseISO } from 'date-fns'

export default {
  name: 'UserEdit',

  setup() {
    const textarea = ''
    const text = ''

    function getvalue() {
      this.text = this.textarea
    }

    return {
      getvalue,
      text,
      textarea,
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
    }
  },
  data() {
    return {
      item: {},
      cities: [],
      showDate: false,
      deleteTemp: false,
      deleteTempName: null,
      isDOB: false,
      isED: false,
      isSD: false,
      process: false,
      langs: [
        {
          text: 'ગુજરાતી',
          value: 5,
        },
        {
          text: 'हिंदी',
          value: 6,
        },
        {
          text: 'English',
          value: 7,
        },
      ],
      types: [
        {
          text: 'Free',
          value: 'Free',
        },
        {
          text: 'Paid',
          value: 'Paid',
        },
        {
          text: 'Demo',
          value: 'Demo',
        },
      ],
      rules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
    computedDateFormattedMomentjs() {
      return this.item.dob ? moment(this.item.dob).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')
    },
    computedStartDate() {
      this.item.plan_end_date = moment(this.item.plan_start_date).add(1, 'Y').format('DD-MM-YYYY')
      return this.item.plan_start_date
        ? moment(this.item.plan_start_date).format('DD-MM-YYYY')
        : moment().format('DD-MM-YYYY')
    },
    computedEndDate() {
      return this.item.plan_end_date
        ? moment(this.item.plan_start_date).add(1, 'Y').format('DD-MM-YYYY')
        : moment().add(1, 'Y').format('DD-MM-YYYY')
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const url = `users/${this.$route.params.id}`
      this.$api.get(url).then(response => {
        this.item = response.data

        if (this.item.profile_img != null) {
          this.item.profile_img = process.env.VUE_APP_MEDIA_URL + this.item.profile_img
        }
        if (this.item.company_logo != null) {
          this.item.company_logo = process.env.VUE_APP_MEDIA_URL + this.item.company_logo
        }
        if (this.item.template_image1 != null) {
          this.item.template_image1 = process.env.VUE_APP_MEDIA_URL + this.item.template_image1
        }
        if (this.item.template_image2 != null) {
          this.item.template_image2 = process.env.VUE_APP_MEDIA_URL + this.item.template_image2
        }
        if (this.item.template_image3 != null) {
          this.item.template_image3 = process.env.VUE_APP_MEDIA_URL + this.item.template_image3
        }
        if (this.item.template_gif1 != null) {
          this.item.template_gif1 = process.env.VUE_APP_MEDIA_URL + this.item.template_gif1
        }
        if (this.item.template_gif2 != null) {
          this.item.template_gif2 = process.env.VUE_APP_MEDIA_URL + this.item.template_gif2
        }
        if (this.item.template_gif3 != null) {
          this.item.template_gif3 = process.env.VUE_APP_MEDIA_URL + this.item.template_gif3
        }
        if (this.item.dob == null) {
          this.item.dob = this.formatDate(new Date())
        } else {
          this.item.dob = this.formatDate(this.item.dob)
        }
        if (this.item.plan_end_date == null) {
          this.item.plan_end_date = this.formatDate(new Date())
        } else {
          this.item.plan_end_date = this.formatDate(this.item.plan_end_date)
        }
        if (this.item.plan_start_date == null) {
          this.item.plan_start_date = this.formatDate(new Date())
        } else {
          this.item.plan_start_date = this.formatDate(this.item.plan_start_date)
        }
        if (this.item.state != null) {
          this.getCities()
        }
      })
    },
    removeTemp(name) {
      this.deleteTemp = true
      this.deleteTempName = name
      this.$api
        .post(`destroy-temp/${this.item.id}`, { name, _method: 'DELETE' })
        .then(response => {
          this.$toast.info(response.message)
          this.deleteTemp = false
          this.deleteTempName = null
          this.item[name] = null
        })
        .catch(error => {
          this.deleteTemp = false
          this.deleteTempName = null
          this.$toast.error(error.response.data.errors[0])
        })
    },
    formatDate(date) {
      // var d = new Date(date),
      //   month = '' + (d.getMonth() + 1),
      //   day = '' + d.getDate(),
      //   year = d.getFullYear()

      // if (month.length < 2) month = '0' + month
      // if (day.length < 2) day = '0' + day

      // return [year, month, day].join('-')
      return format(parseISO(new Date(date).toISOString()), 'yyyy-MM-dd')
    },
    getCities() {
      this.cities = []
      this.$api.get(`get-cities/${this.item.state}`).then(response => {
        this.cities = response.data
      })
    },
    setImg() {
      if (this.item.new_profile_image == null) {
        this.item.profile_img = null
      } else {
        this.item.profile_img = URL.createObjectURL(this.item.new_profile_image)
      }
    },
    setcImg() {
      if (this.item.new_company_logo == null) {
        this.item.company_logo = null
      } else {
        this.item.company_logo = URL.createObjectURL(this.item.new_company_logo)
      }
    },
    setTempImg1() {
      if (this.item.new_temp_img_1 == null) {
        this.item.template_image1 = null
      } else {
        this.item.template_image1 = URL.createObjectURL(this.item.new_temp_img_1)
      }
    },
    setTempImg2() {
      if (this.item.new_temp_img_2 == null) {
        this.item.template_image2 = null
      } else {
        this.item.template_image2 = URL.createObjectURL(this.item.new_temp_img_2)
      }
    },
    setTempImg3() {
      if (this.item.new_temp_img_3 == null) {
        this.item.template_image3 = null
      } else {
        this.item.template_image3 = URL.createObjectURL(this.item.new_temp_img_3)
      }
    },
    setTempGif1() {
      if (this.item.new_temp_gif_1 == null) {
        this.item.template_gif1 = null
      } else {
        this.item.template_gif1 = URL.createObjectURL(this.item.new_temp_gif_1)
      }
    },
    setTempGif2() {
      if (this.item.new_temp_gif_2 == null) {
        this.item.template_gif2 = null
      } else {
        this.item.template_gif2 = URL.createObjectURL(this.item.new_temp_gif_2)
      }
    },
    setTempGif3() {
      if (this.item.new_temp_gif_3 == null) {
        this.item.template_gif3 = null
      } else {
        this.item.template_gif3 = URL.createObjectURL(this.item.new_temp_gif_3)
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.process = true
        this.item._method = 'PUT'
        const options = {
          indices: false,
        }
        const formData = serialize(this.item, options)
        this.$api
          .post(`users/${this.item.id}`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'UserList' })
            this.process = false
          })
          .catch(error => {
            this.process = false
            this.$toast.error(error.response.data.errors[0])
          })
      }
    },
  },
}
</script>
